import { Auth } from "aws-amplify";
const Lambda = require("aws-sdk/clients/lambda");

let lambda;

export async function setCredentialsAWSLambda() {
  const credentials = await Auth.currentCredentials();
  console.log(credentials);
  lambda = new Lambda({
    region: "eu-central-1",
    credentials,
  });
  console.log("Gave lambda?");
  return;
}

export async function getCompanyPinsAWS(company,deleted) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let comp = deleted?`${company}#OUT`:company;
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-pins-company",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company:comp,
        forward: "false",
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getInfoPinAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-pin-info",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getCabinetPinsAWS(cabinet, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cabinet",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        cabinet,
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getEventsAWS(chassis, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-events",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        chassis,
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getEventsPlaceAWS(placeString, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-events",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        placeString,
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getPictureAWS(chassis, timestamp, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-picture",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        chassis: chassis,
        company,
        timestamp,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function changeStatePinAWS(pin, state, company, username,deletePlace) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-change-state",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        pinID: pin.pinID,
        chassis: pin.chassis,
        pinStatus: state,
        username: username,
        company: company,
        deletePlace
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function checkExistenceAWS(pinID, company, username) {
  if (lambda === undefined) {
    console.log("undefined lambda");
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-check-existence",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        pinID,
        company,
        username,
      },
    }),
  };
  console.log(lambda);
  return lambda.invoke(params).promise();
}

export async function checkExistenceCarAWS(chassis, company, username) {
  if (lambda === undefined) {
    console.log("undefined lambda");
    await setCredentialsAWSLambda();
  }
  console.log("checkExistenceCarAWS");

  let params = {
    FunctionName: "rpi-lambda-single-n20-check-existence-car",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        chassis,
        company,
        username,
      },
    }),
  };
  return lambda.invoke(params).promise();
}


export async function getCabinetsCompanyAWS(company) {
  if (lambda === undefined) {
    console.log("undefined lambda");
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cabinets-company",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(lambda);
  return lambda.invoke(params).promise();
}

export async function getPinsCabinetAWS(cabinet, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cabinet",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        cabinet: cabinet,
        company: company,
      },
    }),
  };
  return lambda.invoke(params).promise();
}

export async function deletePinAWS(pin, state, username, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-delete-pin",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        pinID: pin.pinID,
        chassis: pin.chassis,
        pinStatus: state,
        username: username,
        company: company,
        remove: false,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function insertPinDBAWS(
  car,
  pinID,
  username,
  company,
  existingPin
) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-assign-pin",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        car,
        pinID,
        username,
        company,
        existingPin,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getStatesAWS(company, states) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-states",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        states,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getZonesAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-zones",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getFlowAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-flow",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getTasksInProgressAWS(company,cabinet) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-tasks-in-progress",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        cabinet
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function setTaskPersonAWS(company,chassis,assignedUser) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-set-task-person",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        chassis,
        assignedUser
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function deleteTaskPersonAWS(company,chassis) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-delete-task-person",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        chassis
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function resetPasswordAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-reset-password",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function confirmUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-confirm-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function isAdminUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-is-admin-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function listUsersAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-list-users",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function addAdminUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-add-admin-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function addViewUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-add-view-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function addNormalUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-add-normal-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function createUserAWS(email, company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-create-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function removeAdminUserAWS(email) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-remove-admin-user",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        email,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function listBadgesAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-badges",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function updateBadgesAWS(badgeID, company, username) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-update-badge",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        badgeID,
        username,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}



export async function getNewAssetsAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-unassigned-assets",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getAttributesAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-attributes",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function changeAttributesAWS(company,attributes,UUID) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-change-attributes",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        attributes,
        ...(UUID && { UUID }),

      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function changeChangePlaceSettingsAWS(company,changePlace) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-change-change-place-settings",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        changePlace
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function changePlaceAWS(pin,newPlace,username, company) {
  console.log("HEY NEW PLACE")
  console.log(newPlace)
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  console.log(newPlace)

  let hey =  {
    company,
    pinStatus: "Parking Assigned",
    newPlace,
    chassis: pin.chassis,
    pinID: pin.pinID,
    username,
    parkingName:pin.parkingName
  };

  console.log(hey)
  let params = {
    FunctionName: "rpi-lambda-single-n20-change-place",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        pinStatus: "Parking Assigned",
        newPlace,
        chassis: pin.chassis,
        pinID: pin.pinID,
        username,
        parkingName:pin.parkingName
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getDurationAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-duration",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        now: (new Date()).toISOString()
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function addNewPinAWS(company,pinID) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-add-new-pin",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        pinID
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function addManualAssetAWS(uuid,attributes,username,company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-add-asset",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        uuid,
        attributes,
        username,
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getChangePlaceSettingsAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-changeplace-settings",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function checkPlaceOccupiedAWS(company,placeName,parkingName) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-check-place-occupied",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        placeName,
        parkingName
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getCarBrandsAWS() {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-car-brands",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getPopularCarBrandsAWS() {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-popular-car-brands",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getBrandModelsAWS(brand) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-brand-models",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        brand
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getCarsParkingAWS(company,parkingName) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-cars-parking",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        parkingName
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getTimeBetweenEventsAWS(company, filterAttributes,fromEvent,toEvent,limit,deleted,between) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-time-between-events",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company,
        filterAttributes,
        fromEvent,
        toEvent,
        deleted,
        limit,
        between,
        forward:"false"
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}
export async function getPinsDataAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-pins-data",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}

export async function getEventCountAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-event-count",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export async function getAssetsDataAWS(company) {
  if (lambda === undefined) {
    await setCredentialsAWSLambda();
  }
  let params = {
    FunctionName: "rpi-lambda-single-n20-get-assets-data",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({
      pathParameters: {
        company
      },
    }),
  };
  console.log(params);
  return lambda.invoke(params).promise();
}


export function readDataAWS(data) {
  const statusCode = JSON.parse(data.Payload).statusCode;
  const payload = JSON.parse(JSON.parse(data.Payload).body).payLoad;
  const message = JSON.parse(JSON.parse(data.Payload).body).message;
  return { statusCode, payload, message };
}
