import React, { useState, useEffect } from "react";
import { useAppContext } from "./../libs/contextLib";
import NewTable from "./NewTable";
import EventTable from "./EventsTable";
import EventFlow from "./EventsFlow";
import ScanPopup from "../Containers/ScanPopup";
import crossIcon from "./../img/icon-cross.svg";
import {customCarret} from "./../libs/carret";
import {
    readDataAWS,
    getCompanyPinsAWS,
    deletePinAWS,
    changeStatePinAWS,
    checkExistenceAWS,
    insertPinDBAWS,
    getEventsAWS,
    getStatesAWS,
    getZonesAWS,
    getDurationAWS,
    getPinsDataAWS,
    getTimeBetweenEventsAWS,
    getEventCountAWS,
    getAssetsDataAWS,
    changePlaceAWS} from "./../libs/lambdaLib";
import { flattenObject } from "../libs/helperFunctions";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { lastEventId } from "@sentry/react";
import filterFactory,{ multiSelectFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Typeahead } from 'react-bootstrap-typeahead'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




let modalScanTime = 100;

let scanPopupInterval;
let refreshInterval;

//import socketIOClient from "socket.io-client";


let columns = [
];

let attributesAdded = false;


export default function Reports() {
  const [scanData, setScanData] = useState("");
  const [scanShow, isScanShow] = useState(false);
  const [scanPopupTime, setScanPopupTime] = useState(modalScanTime);
  const [pinList, setPinList] = useState([]);
  const {
    scanner,
    company,
    readerOpen,
    username,
    setReaderOpen,
    isFocus,
    attributes,
    UUID,
    changePlace,
    language,
    hasQRCode,
    labelInfo,
    printer
  } = useAppContext();
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalClass, setModalClass] = useState("");
  const [isReadingData, setIsReadingData] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [buttonStates, setButtonStates] = useState([]);
  const [defaultButtonStates, setDefaultButtonStates] = useState("");
  const [newPlace,setNewPlace] = useState("0");
  const [showChangePlace,setShowChangePlace] = useState(false);
  const [QRArray,setQRArray] = useState([]);
  const [zones, setZones] = useState([]);
  const [zoneButtons, setZoneButtons] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totalPins, setTotalPins] = useState(0);
  const [emptyPins, setEmptyPins] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);

  const [barData, setBarData] = useState([{}]);
  const [showExportSettings,setShowExportSettings] = useState(false);
      const [filterAttribute,setFilterAttribute] = useState({});
      const [exportAttributes,setExportAttributes] = useState({ 
        filterAttributes:{
          user: {
            filter: {
              options: [
               "Audi",
               "Auto Renz",
               "Business Center",
               "Citroën DAVO Rotterdam",
               "Citroën DAVO Vlaardingen",
               "DAVO",
               "DAVO Fleetsales RM",
               "DAVO Leidschendam",
               "DAVO Online RM",
               "DAVO Rhoon",
               "Gebruikt handel",
               "Gebruikt import",
               "Gebruikt voorraad",
               "Handelscentrum Rijswijk",
               "Hoeke Pijnacker",
               "Kwintsheul (Deleted)",
               "Leiden 296",
               "Škoda",
               "Stejer - Delft",
               "VW",
               "Wittebrug Autobedrijven",
               "Wittebrug Autobedrijven | Bergschenhoek",
               "Wittebrug Autobedrijven | Fleetsales",
               "Wittebrug Autobedrijven | Forepark",
               "Wittebrug Autobedrijven | Forepark-Rhône Den Haag",
               "Wittebrug Autobedrijven | Kerketuinen",
               "Wittebrug Autobedrijven | Moordrecht",
               "Wittebrug Autobedrijven | Rijswijk",
               "Wittebrug Autobedrijven | Rotterdam",
               "Wittebrug Autobedrijven | Zoetermeer",
               "Wittebrug De Lier Audi",
               "Wittebrug De Lier SEAT",
               "Wittebrug De Lier SKODA",
               "Wittebrug De Lier Volkswagen",
               "Wittebrug De Lier Volkswagen Bedrijfswagen",
               "Wittebrug Delft",
               "Wittebrug Exclusief",
               "Wittebrug Fleetsales",
               "Wittebrug Forepark Audi",
               "Wittebrug Forepark SEAT/CUPRA",
               "Wittebrug Forepark ŠKODA",
               "Wittebrug Forepark Volkswagen",
               "Wittebrug Forepark Volkswagen Bedrijfswagens",
               "Wittebrug Honselersdijk",
               "Wittebrug Kerketuinen",
               "Wittebrug Lease",
               "Wittebrug Leiden",
               "Wittebrug Noordwijk",
               "Wittebrug Voorschoten Audi",
               "Wittebrug Voorschoten SEAT",
               "Wittebrug Voorschoten ŠKODA",
               "Wittebrug Voorschoten Volkswagen",
               "Wittebrug Zoetermeer",
               "Wittebrug Zoetermeer (Argonstraat)"
              ],
          }
      },
    },
      fromEvents: ["Registered"],
      toEvents: ["Delete"],
      defaultDateRange: "1",
      defaultStartDate: "now",
      deleted: true,
     // between: null //["2025-01-01", "2025-02-01"]
  
    });
    const [exportSettings,setExportSettings] = useState({
      filename: "", 
      filterAttributes:{},
    fromEvent: "Registered",
    toEvent: "Delete",
    deleted: true,
    between: ["", ""]

  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  useEffect(() => {
    getDurationPins();
    getTotalPins();
    getEventCount();
    getAssetsCount();
    const today = new Date();
    const lastMonthDate = new Date(today);
    lastMonthDate.setMonth(today.getMonth() - 1);

    setStartDate(lastMonthDate); 

    setExportSettings((prev) => {
      const copy = {...prev};
      copy.between[0] = lastMonthDate.toISOString().split('T')[0];
      copy.between[1] = today.toISOString().split('T')[0];
      console.log(copy);
      return copy;
  })  

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const  onChangeCheck = (attr,value) => {
    setFilterAttribute((prev) => {
        const copy = {...prev};
        copy[attr] =  value;
        console.log(copy);
        return copy;
    })
}


  useEffect(() => {
    if(attributes.length > 0){
      columns = [];
      attributesAdded=false;
      console.log("attributes");
      columns.push({
        dataField: "chassis",
        text: UUID,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
        sort: true,
          });
      for (let i = 0; i < attributes.length; i++) {
        const element = attributes[i];
        console.log(`string${language}`);
        const line =   {
          dataField: element.attribute ,
          text:  element[`string${language}`],
          hidden: element.default===0?true:false,
          sortCaret: (order, column) => {
            return customCarret(order, column);
          },
          sort: true,
        }
        if(element.filter && element.filter.show){
          line.filter = customFilter();
          line.filterRenderer =   (onFilter, column) => {
            return <div class={`form__control`}>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    size='sm'
                    align='left'
                    options={element.filter.options}
                    placeholder={"Selecteer"}
                    onChange={(selected) => {
                      onChangeCheck("user",selected[0])
                      console.log("SELECTED FILTER")
                      console.log(selected[0]);
                       onFilter(selected[0])
                    }}
                    onInputChange={(selected) => {
                      onChangeCheck("user",selected)
                   
                    }}
                    /></div>
           }
        }
        console.log(line.hidden);

        columns.push(line);
      }
      // columns.push({
      //   dataField: "placeName",
      //   text: "Plaats",
      //   sort: true,
      //   sortCaret: (order, column) => {
      //     return customCarret(order, column);
      //   },
      // })
      // columns.push({
      //   dataField: "pinStatus",
      //   text: "Status",
      //   sort: true,
      //   sortCaret: (order, column) => {
      //     return customCarret(order, column);
      //   },
      // })
      columns.push({
        dataField: "timeDiff",
        text: "Tijd",
        sort: true,
        sortCaret: (order, column) => {
          return customCarret(order, column);
        },
      })
      // columns.push({
      //   dataField: "firstEvent",
      //   text: "Doorlooptijd",
      //   sort: true,
      //   sortCaret: (order, column) => {
      //     return customCarret(order, column);
      //   },
      // })
      // columns.push({
      //   dataField: "lastEvent",
      //   text: "Inactiviteit",
      //   sort: true,
      //   sortCaret: (order, column) => {
      //     return customCarret(order, column);
      //   },
      // })
      attributesAdded = true;
    }
  },
  [attributes])


 const getTotalPins = async () => {
  const result = await getPinsDataAWS(company);
  const {totalPins,emptyPins} = readDataAWS(result).payload;
  setTotalPins(totalPins);
  setEmptyPins(emptyPins);

 };

 const getEventCount = async () => {
  const result = await getEventCountAWS(company);
  const {eventCount} = readDataAWS(result).payload;
  setTotalEvents(eventCount);
 };

 const getAssetsCount = async () => {
  const result = await getAssetsDataAWS(company);
  const {deletedCount,count} = readDataAWS(result).payload;
  setTotalAssets(deletedCount + count);
 };

 const handleClickSettings = () => {
  setShowExportSettings(true);
}


 const getTimeBetweenEvents = async () => {

  let result = await getTimeBetweenEventsAWS(company,exportSettings.filterAttributes,exportSettings.fromEvent,exportSettings.toEvent,exportSettings.limit,exportSettings.deleted,exportSettings.between);
  console.log(result)
  result = readDataAWS(result).payload;
  console.log(result)
  for (let i = 0; i < result.length; i++) {
    result[i] = flattenObject(result[i]);
  }
  setPinList(result);
  handleExportClose();


 }
  const getDurationPins = async () => {
    let result = await getDurationAWS(company);
    result = readDataAWS(result).payload;
    let bin = [0,0,0,0,0]
    let binLast = [0,0,0,0,0]

    for (let i = 0; i < result.length; i++) {
      result[i] = flattenObject(result[i]);
      if(result[i].firstEvent < 15){
        bin[0] = bin[0]+1;
      }
      else if(result[i].firstEvent < 29){
        bin[1] = bin[1]+1;
      }
      else if(result[i].firstEvent < 43){
        bin[2] = bin[2]+1;
      }
      else if(result[i].firstEvent < 57){
        bin[3] = bin[3]+1;
      }
      else{
        bin[4] = bin[4]+1;

      }
      if(result[i].lastEvent < 15){
        binLast[0] = binLast[0]+1;
      }
      else if(result[i].lastEvent < 29){
        binLast[1] = binLast[1]+1;
      }
      else if(result[i].lastEvent < 43){
        binLast[2] = binLast[2]+1;
      }
      else if(result[i].lastEvent < 57){
        binLast[3] = binLast[3]+1;
      }
      else{
        binLast[4] = binLast[4]+1;

      }
    }
    //setPinList(result);
    const barChartTicks = [
      {name: "<2w",
        firstEvent:bin[0],
        lastEvent: binLast[0]  
      },
      {name: "<4w",
        firstEvent:bin[1]  ,
        lastEvent: binLast[1]  
      },
      {name: "<6w",
        firstEvent:bin[2],
        lastEvent: binLast[2]    
      },
      {name: "<8w",
        firstEvent:bin[3],
        lastEvent: binLast[3]    
      },
      {name: ">8w",
        firstEvent:bin[4] ,
        lastEvent: binLast[4]   
      }
     ];
     setBarData(barChartTicks)
  };

  const buttonMapping = (array) => {
    return array.flatMap((state) => {
      let item = {
      };
      if (state.action === "CHANGE_STATE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          action : state.action,
          callback: () => {
            handleStateChange(state.state);
          }
        };
      } else if (state.action === "DELETE") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback: handleDelete,
          action: state.action
        };
      }
      else if (state.action === "CHANGE_PLACE" && changePlace["withoutScan"]) {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          disabledInBoard: !changePlace["inBoard"],
          action : state.action,
          callback: handleClickChangePlace
        };
      }  else if (state.action === "SOLD") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          callback: handleSold,
          action : state.action

        };
      } else if (state.action === "ARRAY") {
        item = {
          title: state.name,
          buttonClass: state.buttonClass,
          action : state.action,
          callback: async () => {
            let data = await getStatesAWS(company, state.stateArray);
            const { payload } = readDataAWS(data);
            //setButtonStates(buttonMapping(payload.data));
          }
        };
      }
      else{
        return [];
      }
      return item;
    });
  };


  

  const handleClickChangePlace = () => {
    setShowChangePlace(true)
        closePopup();

      }

      const generateQRarray = (data) => {
        let array = [];
        for (let i = 0; i < labelInfo.length; i++) {
          const element = labelInfo[i];
          console.log(element);
          if(element.attribute){
            if(data[element.attribute]){
              if(element.action){
                if(element.action.function === "substring"){
                  array.push(data[element.attribute].substring(0,10));
                }
              }
              else{
                array.push(data[element.attribute]);
              }
            }
            else{
              array.push("-");
            }
          }
          else if(element.UUID){
            array.push(data.chassis);
          }
      }
      console.log(array);
      return array;
    }


    const handleChangePlace = async (zone) => {
      console.log("zone change place");
      console.log(zone);
      if(zone !== undefined){
        scanData.placeName = zone;
      }
      else{
        scanData.placeName = newPlace;
      }
      scanData.lastUpdate = new Date().toISOString();
      let tempPinList = [...pinList];
      tempPinList.splice(scanData.rowIndex, 1);
      if(scanData.rowIndex===0){
        setPinList([...tempPinList]);
      }
  
      await changePlaceAWS(scanData,scanData.placeName, username, company);
      tempPinList.unshift({ ...scanData });
      setPinList([...tempPinList]);
      scanner.feedbackSignal(2, 4);
      setShowChangePlace(false);
    }


  const handleSold = async () => {
    scanData.pinStatus = "Sold";
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    await deletePinAWS(scanData, "Sold", username, company);
    scanner.feedbackSignal(2, 4);

    closePopup();
  };

  const handleDelete = async () => {
    scanData.pinStatus = "Delete";
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    await deletePinAWS(scanData, "Delete", username, company);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const handleStateChange = async (state) => {
    scanData.pinStatus = state;
    scanData.lastUpdate = new Date().toISOString();
    let tempPinList = [...pinList];
    tempPinList.splice(scanData.rowIndex, 1);
    if(scanData.rowIndex===0){
      setPinList([...tempPinList]);
    }

    await changeStatePinAWS(scanData, state, company, username);
    tempPinList.unshift({ ...scanData });
    setPinList([...tempPinList]);
    scanner.feedbackSignal(2, 4);
    closePopup();
  };

  const onClickRow = async (data, rowIndex) => {
    console.log("Row data " + data);
    data.rowIndex = rowIndex;
    setScanData(data);
    setModalTitle("Pin Info");
    setModalBody(modalBodyScanned(data));
    timerScanPopup();
    if(hasQRCode){
      setQRArray(generateQRarray(data));

    }
    await freeScanning();
    //TODO: What if this is send TWICE??? (clicking twice)
    //ipRenderer.send("assignNewPin", data);
  };

  const closePopup = () => {
    isScanShow(false);
    clearInterval(scanPopupInterval);
    setScanPopupTime(modalScanTime);
    //setButtonStates(buttonMapping(defaultButtonStates));
  };

  const modalBodyScanned = (data) => {
    console.log("scanData");
    console.log(data);
    let body = [];
    body.push(
    <tr>
      <td>{UUID}</td>
      <td>{data.chassis}</td>
    </tr>)
    body.push(attributes.flatMap( (element) => {
      if(element.default){
        return (
          <tr>
          <td>{element[`string${language}`]}</td>
          <td>{data[element.attribute]}</td>
        </tr>)
      }
      else{
        return [];
      }

    }))
    body.push([
      <tr>
      <td>Plaats</td>
      <td>{data.placeName}</td>
    </tr>]
      )
    return (
      <table className="table -small">
        {body}
      </table>
    );
  };

  const onClickHistory = () => {
    getEventsPin(scanData.chassis);
    setShowEvents(true);
  };


  const getEventsPin = async (chassis) => {
    const result = await getEventsAWS(chassis, company);
    const { payload } = readDataAWS(result);
    console.log(payload);

    setEventList(payload);
  };

  const handleExportClose = () => {
    setShowExportSettings(false);
  };

  const timerScanPopup = () => {
    clearInterval(scanPopupInterval);
    isScanShow(true);
    setScanPopupTime(modalScanTime);
    console.log("Scan?");
    scanPopupInterval = setInterval(() => {
      setScanPopupTime((oldValue) => {
        if (oldValue < 2) {
          closePopup();
          return modalScanTime;
        }
        return oldValue - 1;
      });
    }, 1000);
  };

  async function freeScanning() {
    console.log(scanData);
    if (!scanner) {
      return;
    }
    try {
      const result = await scanner.startScanning(modalScanTime * 5);
      if (result) {
        setModalTitle("Data ophalen");
        setModalBody("");
        setIsReadingData(true);
        timerScanPopup();
        const resultExistence = await checkExistenceAWS(
          result,
          company,
          username
        );
        const { statusCode, payload, message } = readDataAWS(resultExistence);
        const payloadExistence = payload;
        console.log(statusCode);
        if (statusCode === 404) {
          setModalTitle("Geen geldige Smartpin");
          setModalBody("");
          setModalClass("danger");
          scanner.feedbackSignal(2, 2);
        } else if (statusCode == 200) {
          setModalTitle("Pin al geregistreerd");
          setModalBody("Gebruik een andere pin");
          setModalClass("warning");
          scanner.feedbackSignal(0, 1);
          return;
        } else {
          await insertPinDBAWS(scanData, result, username, company, true);
          setModalTitle("Nieuwe pin toegewezen");
          setModalBody(modalBodyScanned(scanData));
          setModalClass("success");
          scanner.feedbackSignal(2, 4);
        }
      }
    } catch (e) {
      if (e !== "" && e !== "stopped") {
        setReaderOpen(false);
        console.log(e);
      }
    }
  }

  
  const handleExport = async () => {
    getTimeBetweenEvents();

  }

  return (
    <>
      <section>
        
              <div
                div
                className={`model ${showExportSettings ? "-active" : ""}`}
                data-model="history"
              >
                <div className="model__container">
                  <h1>Zoek Instellingen</h1>
                  <div class={`form__control`}>
                  <label for="Plaats">Gebruiker</label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    size='sm'
                    align='left'
                    options={ exportAttributes.filterAttributes.user.filter.options}
                    placeholder={"Selecteer"}
                    onChange={(selected) => {

                      setExportSettings((prev) => {
                        const copy = {...prev};
                        copy.filterAttributes.user = selected[0];
                        console.log(copy);
                        return copy;
                    })
                            }}
                    onInputChange={(selected) => {
                      setExportSettings((prev) => {
                        const copy = {...prev};
                        copy.filterAttributes.user = selected[0];
                        console.log(copy);
                        return copy;
                    })                   
                    }}
                    /></div>
                                      <div class={`form__control`}>
                                      <label for="Plaats">Event van</label>

                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    size='sm'
                    align='left'
                    options={ exportAttributes.fromEvents}
                    placeholder={"Selecteer"}
                    onChange={(selected) => {

                      setExportSettings((prev) => {
                        const copy = {...prev};
                        copy.fromEvent = selected[0];
                        console.log(copy);
                        return copy;
                    })
                            }}
                    onInputChange={(selected) => {
                      setExportSettings((prev) => {
                        const copy = {...prev};
                        copy.fromEvent = selected[0];
                        console.log(copy);
                        return copy;
                    })                   
                    }}
                    /></div>
                                      <div class={`form__control`}>
                                      <label for="Plaats">Event tot</label>

                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    size='sm'
                    align='left'
                    options={ exportAttributes.toEvents}
                    placeholder={"Selecteer"}
                    onChange={(selected) => {

                      setExportSettings((prev) => {
                        const copy = {...prev};
                        copy.toEvent= selected[0];
                        console.log(copy);
                        return copy;
                    })
                            }}
                    onInputChange={(selected) => {
                      setExportSettings((prev) => {
                        const copy = {...prev};
                        copy.toEvent = selected[0];
                        console.log(copy);
                        return copy;
                    })                   
                    }}
                    /></div>
                    <div><label for="Plaats">Van</label>

                  <DatePicker className="datepicker" selected={startDate} onChange={(date) => {
                    console.log("DatePicker Startdate");
                    console.log();
                    setExportSettings((prev) => {
                      const copy = {...prev};
                      copy.between[0] = date.toISOString().split('T')[0];
                      console.log(copy);
                      return copy;
                  })  
                    setStartDate(date)}} />
                    </div> 
                    <div>
                   <label for="Plaats">Tot</label>

                  <DatePicker className="datepicker" selected={endDate} onChange={(date) => {
                                    console.log();
                    setExportSettings((prev) => {
                      const copy = {...prev};
                      copy.between[1] = date.toISOString().split('T')[0];
                      console.log(copy);
                      return copy;
                  })  
                    setEndDate(date)}} />
</div>
                  <span
          class={"button -open-model"}
          onClick={handleExport}
        >Ophalen</span>       
                  <img
                    src={crossIcon}
                    alt=""
                    className="model__cross"
                    onClick={handleExportClose}
                  />
                </div>
              </div>
        { attributesAdded &&
        <>
        <div class="overview">
        <div class="overview__item">
                  <h1 class="overview__title">Pins</h1>
                  <p>
                  
                    <br />
                    <h1>{`${totalPins-emptyPins}/${totalPins}`}</h1>
                  </p>
                
                </div>
                <div class="overview__item">
                  <h1 class="overview__title">Events Vandaag</h1>
                  <p>
                  
                    <br />
                    <h1>{totalEvents}</h1>
                  </p>
                
                </div>
                <div class="overview__item">
                  <h1 class="overview__title">Auto's doorlopen</h1>
                  <p>
                  
                    <br />
                    <h1>{totalAssets}</h1>
                  </p>
                
                </div>
                                   <BarChart
          width={500}
          height={300}
          data={barData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="firstEvent" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
          <Bar dataKey="lastEvent" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />

        </BarChart>
        </div>
           

        <NewTable onClickRow={onClickRow} pinList={pinList} tableColumns={columns} filter={pinList ? filterFactory:null} csv={true}  onClickSettings={handleClickSettings}  />
        
        </>}
      </section>
    </>
  );
}
