import React, {useState,useEffect, useRef} from "react";
import crossIcon from "./../img/icon-cross.svg";
import { useAppContext } from "./../libs/contextLib";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015

import {
  readDataAWS,
getCarBrandsAWS,
getPopularCarBrandsAWS,
getBrandModelsAWS,
checkExistenceCarAWS
} from "./../libs/lambdaLib";

//const vinDecoder = require('vin-decoder');
const crypto = require('crypto');

export default function AddAssetForm(props) {

    const {
        company,
        username,
        attributes,
        UUID,
        language,
      } = useAppContext();

      const [tempAttributes,setTempAttributes] = useState({})
      const [tempUUID,setTempUUID] = useState("")
      const [formAttributes,setFormAttributes] = useState(<></>)
      const [plate,setPlate] = useState("")
      const [VIN,setVIN] = useState("")
      const [UUIDexists,setUUIDexists] = useState(false)
      const [carBrands,setCarBrands] = useState([])
      const [selectedBrand,setSelectedBrand] = useState("")
      const [popularCarBrands,setPopularCarBrands] = useState([])
      const [carModels,setCarModels] = useState([])
      const [UUIDvalidation,setUUIDvalidation] = useState("")
    const  onChangeCheck = (attr,value) => {
        setTempAttributes((prev) => {
            const copy = {...prev};
            copy[attr] =  value;
            console.log(copy);
            return copy;
        })
    }

    useEffect(()=> {
      getBrands();
    },[])

    const checkCar =  async (uuid) => {
      const data =   await checkExistenceCarAWS(uuid,company,username);
      let code = readDataAWS(data).statusCode;
      console.log("gonna check car " + uuid + " ");
      console.log(code == 404);
      if( code == 404){
        setUUIDexists(false)
      }
      else{
        setUUIDexists(true)

      }
    }

    const getBrands = async () => {

      const data =   await getCarBrandsAWS()
      let brands = readDataAWS(data).payload.brands
      setCarBrands(brands.sort());
      await getPopularBrands();
    }

      const getPopularBrands = async () => {

        const data =   await getPopularCarBrandsAWS()
        let brands = readDataAWS(data).payload.brands
        setPopularCarBrands(brands.sort());

      }


      const getModels= async () => {

        const data =   await getBrandModelsAWS(selectedBrand)
        let models = readDataAWS(data).payload.models
        setCarModels(models.sort());

      }



    useEffect(() => {
        let carBrandsCopy = [...carBrands]
      for (let i = popularCarBrands.length-1; i >=0; i--) {
        const element = popularCarBrands[i];
        carBrandsCopy.splice(carBrandsCopy.indexOf(element), 1);
        carBrandsCopy.unshift(element);
      }
      console.log(carBrandsCopy)
      setCarBrands(carBrandsCopy);

    },[popularCarBrands])

    useEffect(() => {
      console.log("brand");
      console.log(selectedBrand);
      if(selectedBrand && selectedBrand !== ""){
        getModels(selectedBrand);
      }

    },[selectedBrand])

  useEffect(() => {
    if(attributes){
        let buttons = [<></>];
        const uuidForm= (
          <>
                      <div class={`form__control`}>
            <label for="email">{UUID}*</label>
            <span style={{ color: "red" }}>{UUIDvalidation}</span>
            <input type="text" onChange={(e) => setTempUUID(e.target.value)} value={tempUUID} onBlur = {(e) => checkCar(e.target.value)}/>
            <p class="form__error" hidden={!UUIDexists}>
                   Bestaande UUID
                 </p>
          </div>
        </>

        );
        buttons.push(uuidForm)
        for (let i = 0; i < attributes.length; i++) {
          const element = attributes[i];
          if(element.default){
            if(element.attribute === "brand"){
              const typeahead = <div class={`form__control`}>
                  <Typeahead
                                id="basic-typeahead-single"
                                labelKey="name"
                                options={carBrands}
                                placeholder={tempAttributes.brand?tempAttributes.brand:"Merk"}
                                paginationText="Laad meer"
                                maxResults={20}
                                onChange={(selected) => {
                                  setSelectedBrand(selected[0])
                                  onChangeCheck(element.attribute,selected[0])
                                }}
                                onInputChange={(selected) => {
                                  onChangeCheck(element.attribute,selected)
                                }}
                                  /></div>
             buttons.push(typeahead);

            }
            else if(element.attribute === "model"){
              const typeahead =                       <div class={`form__control`}>
              <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="name"
                                            options={carModels}
                                            placeholder={tempAttributes.model?tempAttributes.model:"Model"}
                                            value={tempAttributes.model}
                                            paginationText="Laad meer"
                                            maxResults={20}
                                            onChange={(selected) => {
                                              onChangeCheck(element.attribute,selected[0])
                                            }}
                                            onInputChange={(selected) => {
                                              onChangeCheck(element.attribute,selected)
                                            }}
                                            /></div>
             buttons.push(typeahead);

                        }
            else{
            const button = (
                <div class={`form__control`}>
                <label for="email">{element.attribute}</label>
                <input type="text" onChange={(e) => onChangeCheck(element.attribute,e.target.value)} value={tempAttributes[element.attribute]}/>

              </div>
              );
              buttons.push(button);
            }
          }

        }
        setFormAttributes(buttons);
    }

  },[attributes,tempUUID,tempAttributes,carModels,carBrands,UUIDexists])


useEffect(() => {
  //checkCar(tempUUID);

},[tempUUID])



  const handleClose = () => {
    setTempAttributes({});

    props.handleClose();
  }

 const searchPlate = async () => {
  fetch(`https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${plate}`)
  .then((response) => response.json())
  .then((data) => {
     setTempUUID(data[0].kenteken);
     const item  = {brand: data[0].merk,
                    model : data[0].handelsbenaming,
                    color: data[0].eerste_kleur
     }
     console.log()
     setTempAttributes(item);
  })
  .catch((err) => {
     console.log(err.message);
  });

 }

 const searchVIN = async () => {

  const apiPrefix = "https://api.vindecoder.eu/3.2";
const apiKey = "e1455ea585c5";   // Your API key
const secretKey = "72f076d61f";  // Your secret key
const id = "decode";
const vin = VIN.toUpperCase(); //Seat Mii ???

const controlSum = crypto.createHash('sha1').update(`${vin}|${id}|${apiKey}|${secretKey}`).digest('hex').substring(0, 10);

  fetch(`${apiPrefix}/${apiKey}/${controlSum}/decode/${vin}.json`)
  .then((response) => response.json())
  .then((data) => {
    let item = {};
    let decode = data.decode;
    for (let i = 0; i < decode.length; i++) {
        const element =decode[i];
        if(element.label === 'Make'){
            item.brand = element.value;
        }
        else if(element.label === 'Model'){
            item.model = element.value;
        }
        else if(element.label === 'VIN'){
            item.uuid = element.value;
        }
        
        
    }
     setTempUUID(item.uuid);
     setTempAttributes(item);
  })
  .catch((err) => {
     console.log(err.message);
  });

 }

  return (
    <>
      <div className={`model ${props.show ? "-active" : ""}`}>
        <div class="model__container">
          <h1>Manueel toevoegen</h1>
        {/*   <div class={`form__control`}>

          <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          options={carBrands}
          placeholder="Merk"
          paginationText="Laad meer"
          maxResults={20}
        /> */}
<div class={`form__control`}>
            <label>Kenteken</label>
            <input type="text" onChange={(e) => setPlate(e.target.value)} />
          </div>
          <span
              class="button"
             onClick={ () => {searchPlate()}}
            > Kenteken zoeken</span>
            <div class={`form__control`}>
            <label>VIN</label>
            <input type="text" onChange={(e) => setVIN(e.target.value)} />
          </div>
          <span
              class="button"
             onClick={ () => {searchVIN()}}
            > VIN zoeken</span>
  {formAttributes}
          <img
            src={crossIcon}
            alt=""
            class="model__cross"
            onClick={handleClose}
          />
                            <span
              class="button -wide"
             onClick={ () => {props.postManualAsset(tempUUID,tempAttributes)}}
            > Indienen</span>

            </div>
        </div>
    </>
  );
}
