import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { HashRouter as Router } from "react-router-dom";

import { Amplify } from "aws-amplify";
import config from "./awsConfig";
import { isMobile } from 'react-device-detect';

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

Sentry.init({
  dsn:
    "https://b5f1e189b13f4af3816e911c3fc4a8c6@o499246.ingest.sentry.io/5577574",
});
if(isMobile){
  window.location.replace('https://mobile.codek.be')

}
Amplify.configure({
  Auth: {
    //mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Router forceRefresh={true}>
      <App />
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//serviceWorker.unregister();
